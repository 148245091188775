.paymentTermsLabel {
  margin-bottom: 8px;
}

.rowWrapper {
  display: flex;
  justify-content: space-evenly;

  .fieldWrapper {
    margin-right: 24px;
    margin-bottom: 12px;
    width: 275px;
  }
}